import type { JSX } from 'react';
import { HeroImage } from '@/components';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type HeroImageProps = SliceComponentProps<Content.HeroImageSlice>;

const HeroImageSlice = ({ slice }: HeroImageProps): JSX.Element => {
  return <HeroImage {...slice} />;
};

export default HeroImageSlice;
